<template>
    <div>
        <div class="bkg" v-loading="loading" element-loading-text="loading..." element-loading-spinner="el-icon-loading">
            <div class="getrs">
                <p class="text">Grande surpresa, de gra?a
                    <br />
                    qualquer um pode pegar moedas de ouro
                </p>
                <input type="text" placeholder="Phone" class="inp" v-model="phone" />
                <div class="btn" @click="getGif">
                    Receive
                </div>
                <div class="mony">
                    <image src="../../static/cardgold.png" mode="widthFix"></image>
                    R${{ money }}
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { getCard,usergetCard} from "../../api/index.js";
import { Message } from 'element-ui';
export default {
    data() {
        return {
            code: '',
            phone: '',
            money: '',
            loading:false,
        }
    },
    mounted() {

        this.code = localStorage.getItem('card_code');
        if (this.code) {
            getCard({code:this.code}).then(res=>{
            this.money=res.data.amount
        })   
        }
        
    },
    methods: {
        getGif() {
            this.loading=true
            usergetCard({
                mobile: this.phone,
                code: this.code
            }).then(res => {
                this.loading=false
                if(res!=false){
                    Message.success(res.message)
                }
            })
        },

    }
}
</script>

<style>
.mony {
    width: 200px;
    height: 50px;
    margin: 0 auto;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    background-color: #20242F;
    border-radius: 25px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mony image {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 8px;
}

.bkg {
    width: 100%;
    height: 900px;
    /* padding-bottom: 0px 60px; */
    background: url(../../assets/cardbg.png) no-repeat;
    background-size: 100% 100%;
    margin-top: -20px;
}

.logo {
    display: block;
    margin: 0 auto;
}

.gift {
    display: block;
    margin: 0 auto;
    height: 30%;
}

.text {
    color: white;
    font-size: 25px;
    text-align: center;
    line-height: 30px;
    margin-top: 20px;
}

.inp {
    display: block;
    background-color: #FFFFFF;
    color: #000;
    width: 290px;
    margin-top: 15px;
    border-radius: 25px;
    height: 35px;
    padding: 0 10px;
    font-size: 20px;
    text-align: center;
    margin: 0 auto;

}

.btn {
    width: 310px;
    height: 45px;
    background: linear-gradient(180deg, #41FDFF 0%, #1B6CC8 100%);
    margin: 0 auto;
    margin-top: 17px;
    border-radius: 25px;
    text-align: center;
    line-height: 45px;
    font-size: 25px;
    color: #fff;
}

input::placeholder {
    color: #fff;
}

.getrs {
    position: relative;
    bottom: -450px;
}
</style>
