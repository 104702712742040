
<template>
    <div class="frame-box"
        v-loading="loading" 
        element-loading-text="loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)">
        <div class="list-box">
            <div class="title-box">
                <img :src="pData.icon" />
                <span>{{ pData.title }}</span>
            </div>
            <div class="img-box">
                <div class="img-info" v-for="(item, idx) in gameList" :key="idx">
                    <!-- <img :src="item.icon" @click="gamego(item)" /> -->
                    <img v-lazy="item.icon" @click="gamego(item)" />
                </div>
            </div>
        </div>
        <span class="more-btn" @click="moreData">{{$t('Loadmore')}}</span>
    </div>
</template>

<script>
import { getPgs, getPps, getJls, getPgLive, getTadas,getPgspro } from '@/api/more.js';
import { games } from "@/api/index.js";
export default {
    data() {
        return {
            pData: '',
            gameList: [],
            page: 1,
            loading: false,
        };
    },

    mounted() {
        // this.pData = this.$route.params;
        this.pData = JSON.parse(this.$route.query.p);
        this.page = 1;
        this.getGameData();
    },

    methods: {
        getGameData() {
            this.loading = true;
            const apiFuncs = {
                PGS: getPgs,
                JL: getJls,
                PP: getPps,
                LIVE: getPgLive,
                TADA: getTadas,
                PGSPRO:getPgspro,
            };
            apiFuncs[this.pData.platform]({
                page: this.page,
            }).then(res => {
                // console.log('res==>', res);
                this.loading = false;
                if (!res) return;
                // this.$message({
                //     message: res.message,
                //     type: res.code == 200 ? 'success' : 'error'
                // });
                if (res.code != 200) return;
                const data = res.data.data;
                if (this.page == 1) this.gameList = data;
                else if (data.length > 0) this.gameList.push(...data);
                else this.page -= 1;
            });
        },

        moreData() {
            this.page += 1;
            this.getGameData();
        },

        gamego(item) {
            this.loading = true;
            const paydata = {
                game_code: item.game_code,
                act: 'game_url'
            };
            if (item.platform == "TADA") {
                paydata.act = "tada_game_url"
            } else if (item.platform == 'PGSPRO') {
                paydata.act = "pgpro_oh_game_url"
            } else {
                paydata.act = "game_url"
            }
            games(
            // {
            //     act: 'game_url',
            //     game_code: item.game_code,
            // }
            paydata
            ).then((res) => {
                this.loading = false;
                window.location.href = res.data.url
            })
        },
    },
}
</script>

<style scoped>
.frame-box {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 40px 50px 40px;
}

.frame-box ::v-deep .el-loading-mask {
    position: fixed;
}

/* .frame-box ::v-deep .el-loading-spinner {
    left: 250px;
    right: 0;
    width: auto;
} */

.frame-box ::v-deep .el-icon-loading,
.frame-box ::v-deep .el-loading-text {
  color: #fff;
  font-size: 28px;
}

.list-box {
    width: 100%;
    min-height: 560px;
}

.title-box {
    display: flex;
    align-items: center;
    padding: 10px 0;
    gap: 8px;
}

.title-box img {
    width: 35px;
    height: 35px;
}

.title-box span {
    font-size: 20px;
    color: #fff;
}

.img-box {
    display: flex;
    flex-wrap: wrap;
    /* gap: 30px; */
    margin-top: 12px;
}

.img-info {
    /* width: 160px;
    height: 240px; */
    width: 14.11%;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
}

.img-info img {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 12px;
}

.img-info img:hover {
    border-radius: 12px;
    border: 1px solid #ffc12a;
    transition: all .1s;
}

.more-btn {
    width: 200px;
    line-height: 40px;
    display: block;
    margin: 0 auto;
    background-color: #691616;
    color: #fff;
    font-size: 16px;
    border-radius: 10px;
    text-align: center;
    margin-top: 60px;
    cursor: pointer;
}
</style>