import { render, staticRenderFns } from "./MorePage.vue?vue&type=template&id=3c1a7010&scoped=true"
import script from "./MorePage.vue?vue&type=script&lang=js"
export * from "./MorePage.vue?vue&type=script&lang=js"
import style0 from "./MorePage.vue?vue&type=style&index=0&id=3c1a7010&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c1a7010",
  null
  
)

export default component.exports